<template>
  <v-row class="py-2 px-4">
    <v-col class="d-flex align-center">
      <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>

      <span class="text-caption">{{ `${tokenExp()}` }}</span>

      <v-spacer></v-spacer>

      <v-btn x-small text color="primary" @click="renewSession()">
        Renovar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      sessionBadgeColor: "success",
      now: new Date(),
      renewLoading: true,
      renewSessionInterval: null,
    };
  },
  mounted() {
    this.renewSessionInterval = setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.renewSessionInterval);
  },
  computed: {
    ...mapGetters("auth", [
      "getTokenExp",
      "getHash",
      "getEnableSessionExpiredDialog",
    ]),
  },
  methods: {
    ...mapMutations("auth", ["setSessionExpiredDialog"]),
    ...mapActions("auth", ["hashAuthenticate"]),
    async renewSession() {
      let hash = this.getHash;

      const auth = await this.hashAuthenticate(hash);
      if (auth === "success") {
        this.$toast.success("Sessão renovada com sucesso");
      } else {
        let err = auth;
        this.$fnError(err);
      }
    },
    tokenExp() {
      let tokenExp = "";

      if (this.getTokenExp && !this.isValidToken(this.getTokenExp)) {
        if (this.getEnableSessionExpiredDialog)
          this.setSessionExpiredDialog(true);
        return (tokenExp = "Sessão expirada");
      }

      let now = moment(this.now, "hh:mm:ss");
      let exp = moment.unix(this.getTokenExp, "hh:mm:ss");
      let duration = moment.duration(exp.diff(now))._data;

      let hours =
        duration.hours.toString().length < 2
          ? `0${duration.hours}`
          : `${duration.hours}`;
      let minutes =
        duration.minutes.toString().length < 2
          ? `0${duration.minutes}`
          : `${duration.minutes}`;
      let seconds =
        duration.seconds.toString().length < 2
          ? `0${duration.seconds}`
          : `${duration.seconds}`;
      let time = `${hours}:${minutes}:${seconds}`;

      this.setSessionBadgeColor(hours, minutes);

      tokenExp = `Sessão expira em ${time}`;

      let timeCompare = time.replaceAll(":", "");
      timeCompare = parseInt(timeCompare, 10);
      if (duration.hours < 1 && duration.minutes < 5) {
        if (timeCompare > 1 && this.renewLoading) {
          this.renewLoading = false;
          this.renewSession().then(() => {
            if (duration.hours < 1 && duration.minutes < 5) {
              this.renewLoading = true;
            }
          });
        }
      }

      return tokenExp;
    },
    setSessionBadgeColor(hours, minutes) {
      if (!hours || !minutes) {
        return (this.sessionBadgeColor = "error");
      }

      let h = parseInt(hours);
      let m = parseInt(minutes);

      if (h === 0) {
        if (m >= 10) {
          this.sessionBadgeColor = "success";
        } else if (m < 10 && m > 5) {
          this.sessionBadgeColor = "warning";
        } else if (m < 5) {
          this.sessionBadgeColor = "error";
        }
      } else {
        this.sessionBadgeColor = "success";
      }
    },
    isValidToken(tokenExp) {
      let now = Math.floor(Date.now() / 1000);
      return now - tokenExp <= 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
